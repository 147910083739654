/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

export const TWITTER_URL = 'https://twitter.com/JPoolSolana'
export const TELEGRAM_URL = 'https://t.me/jpool_solana'
export const TELEGRAM_ANNOUNCEMENT_URL = 'https://t.me/jpoolsolana'
export const DISCORD_URL = 'https://discord.gg/qR4BA9QXVR'
export const SVT_URL = 'https://svt.one'

export const GTM_ID = import.meta.env.VITE_GTM_ID
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_ID
export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
